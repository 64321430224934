.wallet-modal {
	&.modal-dialog {
		max-width: 710px;
	}

	&--ledger.modal-dialog {
		max-width: 1047px;
	}

	&__header {
		text-decoration: underline;
		cursor: pointer;
		font-weight: 500;
		font-size: 1rem;

		&:hover {
			outline: none;
		}
	}

	&__label {
		&::before,
		&::after {
			margin-top: 0.375rem;
		}
	}
}
