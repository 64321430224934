@import url('https://fonts.googleapis.com/css2?family=Lato&family=Roboto&display=swap');

.placeholder-pl-none > input::placeholder {
  padding-left: 0 !important;
}

hr.dotted {
  border:none;
  border-top:1px dashed #76BEAB;
  height:1px;
}
/* 
.products-slider > .slick-list {
  height: 210px !important;
}

.products-slider > .slick-track {
  height: 210px !important;
} */

.home-page .slick-list{
  overflow: visible;
}

.placeholder--pl-lg > input::-webkit-input-placeholder {
  padding-left: 20px !important;
}
.placeholder--pl-lg > input:-ms-input-placeholder {
  padding-left: 20px !important;
}
.placeholder--pl-lg > input::-ms-input-placeholder {
  padding-left: 20px !important;
}
.placeholder--pl-lg > input::placeholder {
  padding-left: 20px !important;
}

hr.dotted {
  border: none;
  border-top: 1px dashed #76BEAB;
  height: 1px;
}

/* typography */
.dark {
  color: rgba(255, 255, 255, 0.7);
}

.dark h1,
.dark h2,
.dark h3,
.dark h4,
.dark h5,
.dark h6 {
  color: white;
}

.logoVertical .item {
  opacity: 0;
  transition: 1s;
}
.logoVertical .slick-center .item {
  opacity: 1;
}
.logoVertical .slick-list{
  overflow: visible;
}

.bg-custom-first{
  background: linear-gradient(to right, rgba(18, 23, 29,1) 0%, rgba(18, 23, 29,1) 50%, rgba(18, 23, 29,0) 51%, rgba(18, 23, 29,0) 100%);
}
.bg-custom-last{
  background: linear-gradient(to right, rgba(18, 23, 29,0) 0%, rgba(18, 23, 29,0) 50%, rgba(18, 23, 29,1) 51%, rgba(18, 23, 29,1) 100%);
}
